import { NgModule } from '@angular/core';
import { AoOrenFeatureShellModule } from '@ao-oren/feature-shell';
import { environment } from '@ao-oren/util-environment';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { AppComponent } from './app.component';
import { COMPONENT_MAPPING } from './cms-config/cms-component-mapping.config';
import { cmsDataUrl } from './cms-config/cms-data-url';
import {
  gaTagsConfig,
  googleTagGlobalProps,
} from './ga-tags-config/ga-tags-config';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AoOrenFeatureShellModule.forRoot({
      componentMapping: COMPONENT_MAPPING,
      gaTagsConfig,
      cmsDataUrl,
      googleTagGlobalProps,
    }),
    ScullyLibModule,
  ],
  providers: [
    { provide: 'isDevelopmentEnvironment', useValue: !environment.production }, // to enable debug logger
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { QuotesActions } from '@common/data-access-quotes';
import { SelectPaymentActions } from '@common/data-access-select-payment';
import { PersonalDetailsSharedActions } from '@common/data-access-shared';
import { CreateQuoteResponse } from '@common/util-models';
import { AlertDialogService } from '@domgen/dgx-fe-components-core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { OfferRenewalErrorService } from '@oren/util-foundation';
import { Observable, of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { OfferRenewalApiService } from '../services/offer-renewal-api.service';
import * as OfferRenewalActions from './offer-renewal.actions';

@Injectable()
export class OfferRenewalEffects {
  getOfferQuote$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuote,
        OfferRenewalActions.getEmailOfferQuote,
        OfferRenewalActions.getRedirectOfferQuote
      ),
      exhaustMap((action) => {
        return this.offerRenewalApiService
          .createOfferRenewalQuote(action.offerQuoteRequest)
          .pipe(
            map((quoteResponse: CreateQuoteResponse) =>
              OfferRenewalActions.getOfferQuoteSuccess({
                quoteResponse,
              })
            ),
            catchError((error: HttpErrorResponse) => {
              return action.type ===
                OfferRenewalActions.getRedirectOfferQuote.type
                ? of(
                    OfferRenewalActions.getRedirectOfferQuoteFailure({ error })
                  )
                : of(OfferRenewalActions.getOfferQuoteFailure({ error }));
            })
          );
      })
    )
  );

  getPlanRenewalQuote$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getPlanRenewalQuote,
        OfferRenewalActions.getEmailPlanRenewalQuote,
        OfferRenewalActions.getRedirectPlanRenewalQuote
      ),
      exhaustMap((action) => {
        return this.offerRenewalApiService
          .createOfferRenewalQuote(action.planRenewalQuoteRequest)
          .pipe(
            map((quoteResponse: CreateQuoteResponse) =>
              OfferRenewalActions.getPlanRenewalQuoteSuccess({
                quoteResponse,
              })
            ),
            catchError((error: HttpErrorResponse) => {
              return action.type ===
                OfferRenewalActions.getRedirectPlanRenewalQuote.type
                ? of(
                    OfferRenewalActions.getRedirectPlanRenewalQuoteFailure({
                      error,
                    })
                  )
                : of(
                    OfferRenewalActions.getPlanRenewalQuoteFailure({
                      error,
                    })
                  );
            })
          );
      })
    )
  );

  createQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuote,
        OfferRenewalActions.getPlanRenewalQuote,
        OfferRenewalActions.getRedirectOfferQuote,
        OfferRenewalActions.getRedirectPlanRenewalQuote,
        OfferRenewalActions.getEmailOfferQuote,
        OfferRenewalActions.getEmailPlanRenewalQuote
      ),
      map(() => QuotesActions.createQuote())
    )
  );

  createQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuoteSuccess,
        OfferRenewalActions.getPlanRenewalQuoteSuccess
      ),
      map(({ quoteResponse }) => {
        if (quoteResponse.basket?.items[0]?.data?.quotes?.length > 1) {
          return OfferRenewalActions.multiQuoteError();
        } else {
          return QuotesActions.createQuoteSuccess({
            quoteApiResult: quoteResponse,
          });
        }
      })
    )
  );

  selectFirstPaymentType$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.createQuoteSuccess),
      map(({ quoteApiResult }) => {
        return SelectPaymentActions.selectFirstOrPreferredPaymentOption({
          quoteResponse: quoteApiResult,
        });
      })
    )
  );

  updatePersonalDetailsState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuoteSuccess,
        OfferRenewalActions.getPlanRenewalQuoteSuccess
      ),
      map(({ quoteResponse }) =>
        PersonalDetailsSharedActions.setPersonalDetails({
          payload: {
            address: {
              line1: quoteResponse?.customer?.address?.addressLine1 ?? '',
              line2: quoteResponse?.customer?.address?.addressLine2 ?? '',
              city: '',
              postcode: quoteResponse?.customer?.address?.postalCode ?? '',
              county: quoteResponse?.customer?.address?.addressLine4 ?? '',
            },
            email: quoteResponse?.customer?.email?.main,
            firstName: quoteResponse?.customer?.firstName,
            lastName: quoteResponse?.customer?.surname,
            mobileNumber: quoteResponse?.customer?.telephone?.mobile,
            title: quoteResponse?.customer?.title,
          },
        })
      )
    )
  );

  createQuoteFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfferRenewalActions.getOfferQuoteFailure,
        OfferRenewalActions.getPlanRenewalQuoteFailure,
        OfferRenewalActions.getRedirectOfferQuoteFailure,
        OfferRenewalActions.getRedirectPlanRenewalQuoteFailure
      ),
      map(({ error }) =>
        QuotesActions.createQuoteFailure({
          error,
        })
      )
    )
  );

  handleGetOfferError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfferRenewalActions.getOfferQuoteFailure),
        tap(({ error }) => {
          if (error.status === 404 && error.error.status === 'NO_OFFERS') {
            this.openAlertDialog();
          } else {
            this.errorService.handleHttpError(error, 'offer');
          }
        })
      ),
    { dispatch: false }
  );

  handleGetRenewalError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfferRenewalActions.getPlanRenewalQuoteFailure),
        tap(({ error }) => {
          if (
            error.status === 400 &&
            (error.error.status === 'POSTAL_CODE_MATCH_ERROR' ||
              error.error.message ===
                'ESB server error: Unsuccessful; Plan is not due to renew.')
          ) {
            this.openAlertDialog();
          } else {
            this.errorService.handleHttpError(error, 'renewal');
          }
        })
      ),
    { dispatch: false }
  );

  handleMultiQuoteError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OfferRenewalActions.multiQuoteError),
        tap(() => {
          this.errorService.handleMultiQuoteError();
        })
      ),
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly errorService: OfferRenewalErrorService,
    private readonly offerRenewalApiService: OfferRenewalApiService,
    private readonly alertDialogService: AlertDialogService
  ) {}

  private openAlertDialog(): Observable<void> {
    return this.alertDialogService
      .openError({
        ariaLabel: 'Offer Renewal Error',
        disableClose: true,
        data: {
          title: 'Have you entered the right details?',
          body: [
            "We're having trouble recognising either the <strong>plan number</strong>, <strong>reference number</strong>, or <strong>postcode</strong> you've entered.",
            'Please make sure your postcode is correct and that your plan number has 10 digits and reference number has 12 digits.',
          ],
          primaryCta: {
            text: 'Try again',
          },
        },
      })
      .afterClosed();
  }
}

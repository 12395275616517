import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addNumberSubsequentPaymentsToBasket } from '@common/data-access-quotes';
import { BuildConfigService } from '@common/util-foundation';
import {
  CreateQuoteRequest,
  CreateQuoteResponse,
  GetOfferRenewalQuoteRequest,
} from '@common/util-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OfferRenewalApiService {
  constructor(
    private httpClient: HttpClient,
    private buildConfigService: BuildConfigService
  ) {}

  public createOfferRenewalQuote(
    createQuoteParams: CreateQuoteRequest | GetOfferRenewalQuoteRequest
  ): Observable<CreateQuoteResponse> {
    const config = this.buildConfigService.config;
    const createQuoteUrl = `${config.basketApi}/quote`;
    return this.httpClient
      .post<{ result: CreateQuoteResponse }>(
        createQuoteUrl,
        createQuoteParams,
        {
          observe: 'response',
          headers: {
            ...this.getHeaders(),
          },
        }
      )
      .pipe(
        map(
          (response: HttpResponse<{ result: CreateQuoteResponse }>) =>
            response.body?.result as CreateQuoteResponse
        ),
        map((quoteResponse: CreateQuoteResponse) => ({
          ...quoteResponse,
          basket: addNumberSubsequentPaymentsToBasket(quoteResponse.basket),
        }))
      );
  }

  private getHeaders() {
    return {
      'wl-client': this.buildConfigService.config.wlClient,
      'request-source': this.buildConfigService.config.catalogueStore,
      'request-action': this.buildConfigService.config.action,
    };
  }
}

<sales-loader [@fadeInOut] *ngIf="loading$ | async"></sales-loader>
<sales-header
  [homeNavigationLink]="config.header.home"
  [navigationLinks]="headerNavigationLinks"
></sales-header>
<oren-banner
  *ngIf="currentRoute === routeCheckoutOrderConfirmation"
  [isInsurance$]="isInsurance$"
></oren-banner>
<main>
  <ui-progress-tracker-route-based
    [steps]="progressSteps$ | async"
    [currentRoute]="currentRoute"
  >
  </ui-progress-tracker-route-based>
  <router-outlet></router-outlet>
</main>
<sales-footer></sales-footer>

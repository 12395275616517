import { Injectable } from '@angular/core';
import { BuildConfigService, ErrorService } from '@common/util-foundation';
import { HttpErrorResponse } from '@angular/common/http';
import { OfferRenewalErrorStatus } from '../../models/offer-renewal-error-status.enum';
import { ErrorPageData } from '@common/util-models';

@Injectable({
  providedIn: 'root',
})
export class OfferRenewalErrorService {
  public readonly defaultErrorPageData: ErrorPageData = {
    header: 'Sorry, we lost you there.',
    additionalSupport: '',
    support:
      "It looks like there's been a technical issue but we're working to fix it.",
    contactCustomerService: 'In the meantime, call our friendly team on:',
  };

  constructor(
    private readonly buildConfigService: BuildConfigService,
    private readonly errorService: ErrorService
  ) {
    this.errorService.setDefaultErrorPageData(this.defaultErrorPageData);
  }

  public handleError(errorPageData?: Partial<ErrorPageData>): void {
    this.errorService.handleError(errorPageData);
  }

  public handleHttpError(
    error: HttpErrorResponse,
    type: 'offer' | 'renewal'
  ): void {
    if (
      error?.error?.status === OfferRenewalErrorStatus.InvalidStatus &&
      type === 'offer'
    ) {
      this.handleError({
        header: "Sorry, you can't activate this offer.",
        support:
          'Not to worry, you can take out protection for your appliance quickly and easily online.',
        link: {
          ...this.buildConfigService.config.header.products,
          label: 'Get protected now',
        },
      });
    } else if (
      error?.error?.status === OfferRenewalErrorStatus.InvalidStatus &&
      type === 'renewal'
    ) {
      this.handleError({
        header: "Sorry, you can't renew your current plan.",
        support:
          'Not to worry, you can take out protection for your appliance quickly and easily online.',
        link: {
          ...this.buildConfigService.config.header.products,
          label: 'Get protected now',
        },
      });
    } else {
      this.handleError();
    }
  }

  public handleMultiQuoteError(): void {
    this.handleError({
      header: "Sorry, we can't activate this quote.",
      support:
        'Unfortunately we can’t continue online as there are multiple quotes for the Reference provided.',
      contactCustomerService: 'Please contact  our Customer Service at:',
    });
  }
}

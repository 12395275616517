import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { BuildConfigService } from '@common/util-foundation';
import { OffersRenewalsCommonSelectorsFacade } from '@oren/data-access-offer-renewal';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable()
export class OfferRenewalMultiplePaymentOptionsGuard implements CanActivate {
  constructor(
    private buildConfigService: BuildConfigService,
    private offersRenewalsCommonSelectorsFacade: OffersRenewalsCommonSelectorsFacade,
    private router: Router
  ) {}

  public canActivate(): Observable<boolean> {
    return combineLatest([
      this.offersRenewalsCommonSelectorsFacade
        .offerRenewalHasMultiplePaymentOptions$,
      this.offersRenewalsCommonSelectorsFacade
        .offerRenewalHasCardPaymentOption$,
      this.offersRenewalsCommonSelectorsFacade
        .offerRenewalHasDirectDebitPaymentOption$,
    ]).pipe(
      first(),
      map(
        ([
          hasMultiplePaymentOptions,
          hasCardPaymentOption,
          hasDirectDebitPaymentOption,
        ]: boolean[]) => {
          if (!hasMultiplePaymentOptions && hasCardPaymentOption) {
            this.router.navigateByUrl(
              this.buildConfigService.config.checkoutCardPaymentPage
            );
          } else if (
            !hasMultiplePaymentOptions &&
            hasDirectDebitPaymentOption
          ) {
            this.router.navigateByUrl(
              this.buildConfigService.config.checkoutDirectDebitPage
            );
          }

          return hasMultiplePaymentOptions;
        }
      )
    );
  }
}

import { Inject, Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Resolve } from '@angular/router';
import { QuotesCommonSelectorsFacade } from '@common/data-access-quotes';
import {
  BuildConfigService,
  POSTCODE_REGEX,
  QuoteParamsParserService,
  REFERENCE_NUMBER_REGEX,
  RENEWAL_REGEX,
  WINDOW,
} from '@common/util-foundation';
import {
  OfferRenewalHomePageRedirect,
  ParseResponse,
  ParseResult,
  REFERRER,
} from '@common/util-models';
import { AlertDialogService } from '@domgen/dgx-fe-components-core';
import { OfferRenewalLandingPageFacade } from '@oren/data-access-offer-renewal';
import { formatReferenceNumber } from '@oren/util-foundation';
import {
  combineLatest,
  MonoTypeOperatorFunction,
  Observable,
  of,
  OperatorFunction,
  pipe,
} from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';

type OrenReferrerType = {
  [key: string]: {
    redirect: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class OfferRenewalQueryParamsResolver
  implements
    Resolve<
      Observable<
        | [
            ParseResponse<OfferRenewalHomePageRedirect>,
            ParseResponse<OfferRenewalHomePageRedirect>
          ]
        | void
      >
    >
{
  private defaultReferrer = 'dg';

  private postCodeValidation = [
    Validators.required,
    Validators.pattern(POSTCODE_REGEX),
  ];

  private planNumberValidation = [
    Validators.required,
    Validators.pattern(RENEWAL_REGEX),
  ];

  private referenceNumberValidation = [
    Validators.required,
    Validators.pattern(REFERENCE_NUMBER_REGEX),
  ];

  constructor(
    @Inject(WINDOW)
    private window: Window,
    private alertDialogService: AlertDialogService,
    private offerRenewalFacade: OfferRenewalLandingPageFacade,
    private quoteParamsParserService: QuoteParamsParserService<OfferRenewalHomePageRedirect>,
    private quotesCommonFacade: QuotesCommonSelectorsFacade,
    private buildConfig: BuildConfigService
  ) {}

  resolve(): Observable<
    | [
        ParseResponse<OfferRenewalHomePageRedirect>,
        ParseResponse<OfferRenewalHomePageRedirect>
      ]
    | void
  > {
    const renewal$: Observable<ParseResponse<OfferRenewalHomePageRedirect>> =
      this.quoteParamsParserService.parse({
        [REFERRER]: [],
        referenceNo: this.planNumberValidation,
        postCode: this.postCodeValidation,
      });

    const offer$: Observable<ParseResponse<OfferRenewalHomePageRedirect>> =
      this.quoteParamsParserService.parse({
        [REFERRER]: [],
        referenceNo: this.referenceNumberValidation,
        postCode: this.postCodeValidation,
      });

    return combineLatest([renewal$, offer$]).pipe(
      first(),
      switchMap(([renewal, offer]) =>
        this.isParsingSuccessful(renewal, offer)
          ? this.submitAndWait(renewal, offer)
          : this.openAlertDialog(renewal, offer)
      )
    );
  }

  private submitAndWait(
    renewal: ParseResponse<OfferRenewalHomePageRedirect>,
    offer: ParseResponse<OfferRenewalHomePageRedirect>
  ): Observable<
    | [
        ParseResponse<OfferRenewalHomePageRedirect>,
        ParseResponse<OfferRenewalHomePageRedirect>
      ]
    | void
  > {
    return (
      of([renewal, offer]) as Observable<
        [
          ParseResponse<OfferRenewalHomePageRedirect>,
          ParseResponse<OfferRenewalHomePageRedirect>
        ]
      >
    ).pipe(this.getOfferOrRenewal(), this.openAlertDialogOnFailure());
  }

  private isParsingSuccessful(
    renewal: ParseResponse<OfferRenewalHomePageRedirect>,
    offer: ParseResponse<OfferRenewalHomePageRedirect>
  ) {
    return (
      renewal.result === ParseResult.Success ||
      offer.result === ParseResult.Success
    );
  }

  private getOfferOrRenewal(): MonoTypeOperatorFunction<
    [
      ParseResponse<OfferRenewalHomePageRedirect>,
      ParseResponse<OfferRenewalHomePageRedirect>
    ]
  > {
    return pipe(
      tap(([renewalParsingResult, offerParsingResult]) => {
        const referrer = this.getReferrer(
          renewalParsingResult,
          offerParsingResult
        );

        const offerReferenceNumber = formatReferenceNumber(
          offerParsingResult.model?.referenceNo
        );

        if (renewalParsingResult.result === ParseResult.Success) {
          this.offerRenewalFacade.getRedirectRenewal(
            renewalParsingResult.model?.referenceNo as string,
            renewalParsingResult.model?.postCode as string,
            referrer
          );
        }

        if (
          offerParsingResult.result === ParseResult.Success &&
          offerReferenceNumber
        ) {
          this.offerRenewalFacade.getRedirectOffer(
            offerReferenceNumber,
            offerParsingResult.model?.postCode as string,
            referrer
          );
        }
      })
    );
  }

  private openAlertDialogOnFailure(): OperatorFunction<
    [
      ParseResponse<OfferRenewalHomePageRedirect>,
      ParseResponse<OfferRenewalHomePageRedirect>
    ],
    void
  > {
    return pipe(
      switchMap(([renewalParsingResult, offerParsingResult]) =>
        this.quotesCommonFacade.quoteError$.pipe(
          switchMap(() =>
            this.openAlertDialog(renewalParsingResult, offerParsingResult)
          )
        )
      )
    );
  }

  openAlertDialog(
    renewalParsingResult: ParseResponse<OfferRenewalHomePageRedirect>,
    offerParsingResult: ParseResponse<OfferRenewalHomePageRedirect>
  ): Observable<void> {
    const referrers = this.buildConfig.config.referrers as OrenReferrerType;
    const referrer = this.getReferrer(renewalParsingResult, offerParsingResult);
    const tryAgainRedirect = referrers[referrer].redirect;

    return this.alertDialogService
      .openError({
        ariaLabel: 'Offer Renewal Error',
        disableClose: true,
        data: {
          title: 'Have you entered the right details?',
          body: [
            "We're having trouble recognising either the <strong>plan number</strong>, <strong>reference number</strong>, or <strong>postcode</strong> you've entered.",
            'Please make sure your postcode is correct and that your plan number has 10 digits and reference number has 12 digits.',
          ],
          primaryCta: {
            text: 'Try again',
            click: () => this.window.location.replace(tryAgainRedirect),
          },
        },
      })
      .afterClosed();
  }

  private getReferrer(
    renewalParsingResult: ParseResponse<OfferRenewalHomePageRedirect>,
    offerParsingResult: ParseResponse<OfferRenewalHomePageRedirect>
  ) {
    const referrers = this.buildConfig.config.referrers as OrenReferrerType;

    const referrer = (
      renewalParsingResult?.model?.[REFERRER] ||
      offerParsingResult?.model?.[REFERRER] ||
      this.defaultReferrer
    ).toLocaleLowerCase();

    return referrers[referrer] ? referrer : this.defaultReferrer;
  }
}

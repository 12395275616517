import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import type { detailData } from '@domgen/dgx-fe-components-core';
import {
  BasketItemPaymentOption,
  CheckoutPlanDetails,
  ItemType,
  PaymentTerm,
  PaymentType,
} from '@common/util-models';
import { DecimalPipe, DatePipe } from '@angular/common';
import { DateWithOrdinalPipe } from '@common/util-foundation';
import { QuoteDocument } from '@domgen/dgx-fe-business-models';

//todo this is a clone of the whitelabel component, rather than copy and modify we should be able to align both journeys
//     - OREN is just another sales journey after all
@Component({
  selector: 'oren-appliance-plan-detail',
  templateUrl: './appliance-plan-detail.component.html',
  styleUrls: ['./appliance-plan-detail.component.scss'],
  providers: [DateWithOrdinalPipe, DecimalPipe],
})
export class OrenAppliancePlanDetailComponent implements OnChanges {
  @Input() planDetails: CheckoutPlanDetails | undefined | null;
  @Input() isInsurance: boolean | null = false;
  @Input() terms: QuoteDocument | undefined = undefined;
  @Input() productInfo: QuoteDocument | undefined = undefined;
  @Output() termsClick: EventEmitter<void> = new EventEmitter();

  detail: detailData[] = [];
  brand = '';
  applianceName = '';

  get paidByDirectDebit() {
    return (
      this.paymentOption &&
      this.paymentOption?.paymentType === PaymentType.DirectDebit
    );
  }

  get planOrPolicy() {
    return this.isInsurance ? 'policy' : 'plan';
  }

  get paymentOption() {
    return this.planDetails?.paymentOption;
  }

  get isTermPlan() {
    return this.planDetails?.paymentTerm === PaymentTerm.FixedTerm;
  }

  constructor(
    private _decimalPipe: DecimalPipe,
    private _datePipe: DatePipe,
    private datePipe: DateWithOrdinalPipe
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.planDetails) {
      return;
    }
    if (this.planDetails) {
      this.mapToDetailArray(this.planDetails);
    }
  }

  mapToDetailArray(planDetails: CheckoutPlanDetails) {
    let day;
    const startDate =
      planDetails?.itemType === ItemType.Renewal
        ? planDetails?.confirmation?.coverStartDate
        : planDetails?.confirmation?.breakdownStartDate;

    if (planDetails?.applianceDetails?.applianceName) {
      this.applianceName = planDetails.applianceDetails.applianceName;
    }
    if (planDetails?.applianceDetails?.brandName) {
      this.brand = planDetails.applianceDetails.brandName;
    }

    this.detail = [
      {
        key: `Your ${this.isInsurance ? 'policy' : 'plan'} number`,
        value: `${planDetails.confirmation?.planNumber}`,
      },
    ];

    if (this.paidByDirectDebit) {
      this.addMonthlyPayment(this.paymentOption as BasketItemPaymentOption);

      this.detail.push({
        key: 'Payment method',
        value: 'Direct debit',
      });

      if (this.planDetails?.paymentDay) {
        day = this.getDaySuffix(this.planDetails.paymentDay);
        this.detail.push({
          key: 'Monthly payment date',
          value: `Day ${day} of each month`,
        });
      }
    } else if (this.paymentOption) {
      this.detail.push({
        key: 'One-time payment',
        value: `One-time payment of £${this._decimalPipe.transform(
          this.paymentOption.fee,
          '1.2-2'
        )}`,
      });
      this.detail.push({
        key: 'Payment method',
        value: 'Credit or debit card',
      });
    }

    if (startDate) {
      const planStartDateObj = {
        key: `${this.isInsurance ? 'Policy' : 'Plan'} start date`,
        value: this._datePipe.transform(startDate, 'dd/MM/yyyy'),
      };
      this.detail.splice(2, 0, planStartDateObj);
    }

    if (this.paidByDirectDebit) {
      this.detail.push({
        key: 'Your Direct Debit payments',
        value: `Your first payment will be on ${day} and then all subsequent payments will be debited from your account each month.`,
      });
    }
  }

  getDaySuffix(day: number) {
    const now = new Date();
    const date = new Date(now.getFullYear(), now.getMonth(), day);
    return this.datePipe.transform(date, 'do');
  }

  addMonthlyPayment(paymentOption: BasketItemPaymentOption) {
    let value = `£${this._decimalPipe.transform(
      paymentOption.subsequentPayment,
      '1.2-2'
    )} a month`;

    if (paymentOption?.numPayments === 0) {
      value = `£${this._decimalPipe.transform(
        paymentOption.subsequentPayment,
        '1.2-2'
      )} a month`;
    } else if (paymentOption.firstPayment !== paymentOption.subsequentPayment) {
      value = `1 payment of £${this._decimalPipe.transform(
        paymentOption.firstPayment,
        '1.2-2'
      )}<br/>then ${
        paymentOption.numSubsequentPayments
      } monthly payments of £${this._decimalPipe.transform(
        paymentOption.subsequentPayment,
        '1.2-2'
      )}`;
    }

    this.detail.push({
      key: 'Monthly payments',
      value,
    });
  }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SelectedPaymentTypeGuard } from '@common/data-access-select-payment';
import { Error404Component } from '@common/ui-whitelabel-components';
import { CmsPage, OFFER, PaymentType, RENEWAL } from '@common/util-models';
import {
  OfferQueryParamsResolver,
  OfferRenewalGuard,
  OfferRenewalMultiplePaymentOptionsGuard,
  OfferRenewalQueryParamsResolver,
  RenewalQueryParamsResolver,
} from '@oren/feature-shell';
import { ErrorComponent } from './components/error/error.component';

const routes: Routes = [
  //todo: what should the default page actually be?
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/offer',
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('@ao-oren/feature-landing').then(
        (m) => m.AoOrenFeatureLandingModule
      ),
    resolve: {
      quoteParamsParseResponse: OfferRenewalQueryParamsResolver,
    },
  },
  {
    path: 'offer',
    loadChildren: () =>
      import('@ao-oren/feature-landing').then(
        (m) => m.AoOrenFeatureLandingModule
      ),
    data: {
      cmsDataPage: CmsPage.OrenLandingOffer,
      referenceType: OFFER,
    },
    resolve: {
      quoteParamsParseResponse: OfferQueryParamsResolver,
    },
  },
  {
    path: 'renewal',
    loadChildren: () =>
      import('@ao-oren/feature-landing').then(
        (m) => m.AoOrenFeatureLandingModule
      ),
    data: {
      cmsDataPage: CmsPage.OrenLandingRenewal,
      referenceType: RENEWAL,
    },
    resolve: {
      quoteParamsParseResponse: RenewalQueryParamsResolver,
    },
  },
  {
    path: 'quote',
    loadChildren: () =>
      import('@oren/feature-quote').then((m) => m.FeatureQuoteModule),
    canActivate: [OfferRenewalGuard],
    data: {
      redirectTo: '/',
    },
  },
  {
    path: 'checkout',
    children: [
      {
        path: 'personal-details',
        loadChildren: () =>
          import('@oren/feature-checkout-personal-details').then(
            (m) => m.OrenFeatureCheckoutPersonalDetailsModule
          ),
      },
      {
        path: 'select-payment',
        loadChildren: () =>
          import('@oren/feature-checkout-select-payment').then(
            (m) => m.OrenFeatureCheckoutSelectPaymentModule
          ),
        canActivate: [OfferRenewalMultiplePaymentOptionsGuard],
      },
      {
        path: 'direct-debit-payment',
        loadChildren: () =>
          import('@oren/feature-checkout-direct-debit').then(
            (m) => m.OrenFeatureCheckoutDirectDebitModule
          ),
        canActivate: [SelectedPaymentTypeGuard],
        data: {
          paymentType: PaymentType.DirectDebit,
        },
      },
      {
        path: 'card-payment',
        loadChildren: () =>
          import('@oren/feature-checkout-card-payment').then(
            (m) => m.OrenFeatureCheckoutCardPaymentModule
          ),
        canActivate: [SelectedPaymentTypeGuard],
        data: {
          paymentType: PaymentType.Card,
        },
      },
      {
        path: 'order-confirmation',
        loadChildren: () =>
          import('@ao-oren/feature-order-confirmation').then(
            (m) => m.AoOrenFeatureOrderConfirmationModule
          ),
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'personal-details',
      },
    ],
    canActivate: [OfferRenewalGuard],
    data: {
      redirectTo: '/',
    },
  },
  {
    path: '**',
    component: Error404Component,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class AoOrenFeatureShellRoutingModule {} // Main routes for application

<ng-container *ngIf="vm$ | async as vm">
  <div class="form-panel">
    <dgx-dfb-form
      [fieldset]="vm.fieldDef"
      [form]="vm.formGroup"
      [validate]="vm.validate"
      (validatedSubmit)="handleValidFormSubmission($any($event))"
      (valueChanges)="handleValueChanges($event)"
      (analytics)="handleAnalytics($event)"
    >
    </dgx-dfb-form>
  </div>
</ng-container>

<div class="header-section">
  <h2 class="header-title">Just give us a few details to get started</h2>
  <div class="header-sub-title">
    <span *ngIf="referenceType === 'offer'"
      >Please enter the reference number that you received from us so we can
      find your quote</span
    >
    <span *ngIf="referenceType === 'renewal'"
      >Please enter your unique plan number so we can quickly find your
      account</span
    >
  </div>
</div>

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Insurance, Maintenance } from '@oren/util-foundation';

@Component({
  selector: 'oren-quote-cover-inclusions',
  templateUrl: './quote-cover-inclusions.component.html',
  styleUrls: ['./quote-cover-inclusions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteCoverInclusionsComponent {
  @Input() cmsData: Insurance | Maintenance | null | undefined;
}

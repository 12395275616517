import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ENVIRONMENT,
  UrlQueryParamsParser,
  WINDOW,
} from '@common/util-foundation';
import { ConfigAdapter, Environment } from '@common/util-models';

@Injectable()
export class QuotePageUrlConfigAdapter implements ConfigAdapter {
  utmCampaignQueryParam = 'utm_campaign';
  utmContentQueryParam = 'utm_content';
  utmMediumQueryParam = 'utm_medium';
  utmSourceQueryParam = 'utm_source';

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    @Inject(WINDOW) private window: Window,
    private queryParamParser: UrlQueryParamsParser
  ) {}

  getConfig(): { quotePage: string | undefined } {
    const queryParams = this.queryParamParser.parseQueryParams(
      this.window.location.search
    );

    let httpParams: HttpParams;
    const isUtmMediumPrint = queryParams[this.utmMediumQueryParam] === 'Print';
    const hasRequiredParams =
      queryParams[this.utmCampaignQueryParam] &&
      queryParams[this.utmMediumQueryParam] &&
      queryParams[this.utmSourceQueryParam];

    if (hasRequiredParams) {
      const fromObject = {
        [this.utmMediumQueryParam]: queryParams[this.utmMediumQueryParam],
        [this.utmSourceQueryParam]: queryParams[this.utmSourceQueryParam],
        [this.utmCampaignQueryParam]: queryParams[this.utmCampaignQueryParam],
      };

      if (!isUtmMediumPrint && queryParams[this.utmContentQueryParam]) {
        fromObject[this.utmContentQueryParam] =
          queryParams[this.utmContentQueryParam];
      }

      httpParams = new HttpParams({ fromObject });

      return {
        quotePage: `${this.environment.quotePage}?${httpParams.toString()}`,
      };
    }

    return {
      quotePage: this.environment.quotePage,
    };
  }
}

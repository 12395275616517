<div class="cover-duration-summary" *ngIf="cmsData">
  <h2 class="cover-duration-summary__title">
    {{ cmsData.title | interpolate: { planOrPolicy: planOrPolicy } }}
  </h2>
  <p class="cover-duration-summary__description">
    <ng-container
      *ngIf="coverPaymentTerm === paymentTerm.FixedTerm; else defaultSummary"
    >
      {{
        fixedTermCmsData
          | interpolate
            : {
                coverDurationInMonths: coverDurationInMonths
              }
      }}
    </ng-container>

    <ng-template #defaultSummary>
      {{
        cmsData.summary
          | interpolate
            : {
                planOrPolicy: planOrPolicy
              }
      }}
    </ng-template>
  </p>
</div>

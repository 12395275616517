<div class="hero-banner">
  <div data-automation="hero-banner" class="container">
    <div class="text-center">
      <h1>Thanks, you’re all set!</h1>
      <p>
        We'll email your
        {{ (isInsurance$ | async) ? 'policy' : 'plan' }} documents and
        quick-access link to My Account shortly
      </p>
    </div>
  </div>
</div>

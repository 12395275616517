import { Component, Input } from '@angular/core';

@Component({
  selector: 'sales-cover-starting-date',
  templateUrl: './cover-starting-date.component.html',
  styleUrls: ['./cover-starting-date.component.scss'],
})
export class CoverStartingDateComponent {
  @Input() isHeating: boolean | null = false;
  @Input() panel = true;
  @Input() showPDContent: boolean | null = false;
  @Input() hasMultiQuotes: boolean | null = false;
}

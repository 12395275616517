import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  BasketItemPaymentOption,
  CoverPaymentOptions,
  PaymentType,
} from '@common/util-models';

@Component({
  selector: 'oren-dont-miss-out-area',
  templateUrl: './dont-miss-out-area.component.html',
  styleUrls: ['./dont-miss-out-area.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DontMissOutAreaComponent {
  @Input() name: string | undefined;
  @Input() set paymentOptions(paymentOptions: CoverPaymentOptions | undefined) {
    this.getPaymentInfo(paymentOptions);
  }
  isCard = false;
  preferredPaymentOption = {} as BasketItemPaymentOption;

  getPaymentInfo(paymentOptions?: CoverPaymentOptions) {
    if (!paymentOptions) {
      return;
    }
    const productOptionArray = Object.values(paymentOptions).filter(
      (paymentOption?: BasketItemPaymentOption) => !!paymentOption
    );

    if (!productOptionArray.length) {
      return;
    }
    this.preferredPaymentOption =
      Object.values(paymentOptions).find(
        (paymentOption: BasketItemPaymentOption) =>
          paymentOption?.preferredPayment
      ) || productOptionArray[0];

    this.isCard = this.preferredPaymentOption?.paymentType === PaymentType.Card;
  }
}

import { StopAutoRenewalCode } from '@common/util-models';
import { Action, createReducer, on } from '@ngrx/store';
import { CheckoutPersonalDetailsFormValue } from '@oren/util-foundation';
import * as CheckoutActions from './checkout.actions';

export const OREN_CHECKOUT_FEATURE_KEY = 'offerRenewal.checkout';

export interface CheckoutState {
  email: string | undefined;
  termsAndConditionsAccepted: boolean;
  autoRenewalPreference?: StopAutoRenewalCode | undefined;
}

export const initialState: CheckoutState = {
  email: undefined,
  termsAndConditionsAccepted: false,
  autoRenewalPreference: undefined,
};

const checkoutReducer = createReducer(
  initialState,
  on(
    CheckoutActions.checkoutPersonalDetailsFormSubmit,
    (
      state: CheckoutState,
      payload: { formValue: CheckoutPersonalDetailsFormValue }
    ): CheckoutState => {
      return {
        ...state,
        ...payload.formValue,
      };
    }
  ),
  on(
    CheckoutActions.saveAutoRenewalPreference,
    (state, { autoRenewalPreference }) => ({
      ...state,
      autoRenewalPreference,
    })
  )
);

export function reducer(state: CheckoutState | undefined, action: Action) {
  return checkoutReducer(state, action);
}

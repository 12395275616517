import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Cover } from '@common/util-models';
import { OffersRenewalsCommonSelectorsFacade } from '@oren/data-access-offer-renewal';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

@Injectable()
export class OfferRenewalGuard implements CanActivate {
  constructor(
    private offersRenewalsCommonSelectorsFacade: OffersRenewalsCommonSelectorsFacade,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const redirectTo: string = route.data?.redirectTo ?? '/';

    return this.offersRenewalsCommonSelectorsFacade.offerRenewal$.pipe(
      first(),
      map((offerRenewal: Cover | undefined) => !!offerRenewal),
      tap((canActivate: boolean) => {
        if (!canActivate) {
          this.router.navigateByUrl(redirectTo);
        }
      })
    );
  }
}

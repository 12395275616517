import { Component, EventEmitter, Input, Output } from '@angular/core';
import { goToMyAccount } from '@common/util-models';

@Component({
  selector: 'oren-go-to-my-account',
  templateUrl: './go-to-my-account.component.html',
  styleUrls: ['./go-to-my-account.component.scss'],
})
export class GoToMyAccountComponent {
  @Input() link: string | undefined;
  @Input() content: goToMyAccount | undefined;
  @Output() myAccountClick: EventEmitter<void> = new EventEmitter();

  redirectToMyAccount() {
    if (this.link) {
      window.open(this.link, '_blank');
    }
    this.myAccountClick.emit();
  }
}

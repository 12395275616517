import { Injectable } from '@angular/core';
import { Validators } from '@angular/forms';
import { Resolve } from '@angular/router';
import {
  POSTCODE_REGEX,
  QuoteParamsParserService,
  RENEWAL_REGEX,
} from '@common/util-foundation';
import {
  ParseResponse,
  ParseResult,
  RenewalFormSubmit,
} from '@common/util-models';
import { OfferRenewalLandingPageFacade } from '@oren/data-access-offer-renewal';
import {
  MonoTypeOperatorFunction,
  NEVER,
  Observable,
  of,
  OperatorFunction,
  pipe,
} from 'rxjs';
import { first, map, switchAll, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RenewalQueryParamsResolver
  implements Resolve<Observable<ParseResponse<RenewalFormSubmit>>>
{
  private postCodeValidation = [
    Validators.required,
    Validators.pattern(POSTCODE_REGEX),
  ];

  private planNumberValidation = [
    Validators.required,
    Validators.pattern(RENEWAL_REGEX),
  ];

  constructor(
    private offerRenewalFacade: OfferRenewalLandingPageFacade,
    private quoteParamsParserService: QuoteParamsParserService<RenewalFormSubmit>
  ) {}

  resolve(): Observable<ParseResponse<RenewalFormSubmit>> {
    return this.quoteParamsParserService
      .parse({
        planNumber: this.planNumberValidation,
        postCode: this.postCodeValidation,
      })
      .pipe(
        first(),
        this.submitQuoteIfParsingSuccessful(),
        this.waitIfParsingSuccessful()
      );
  }

  private submitQuoteIfParsingSuccessful(): MonoTypeOperatorFunction<
    ParseResponse<RenewalFormSubmit>
  > {
    return pipe(
      tap((parsedResponse) => {
        if (
          parsedResponse.result === ParseResult.Success &&
          parsedResponse.model
        ) {
          this.offerRenewalFacade.getEmailRenewal(
            parsedResponse.model?.planNumber as string,
            parsedResponse.model?.postCode as string
          );
        }
      })
    );
  }

  private waitIfParsingSuccessful(): OperatorFunction<
    ParseResponse<RenewalFormSubmit>,
    ParseResponse<RenewalFormSubmit>
  > {
    return pipe(
      map((parsedResponse) =>
        parsedResponse.result === ParseResult.Success
          ? NEVER
          : of(parsedResponse)
      ),
      switchAll()
    );
  }
}

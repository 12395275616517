import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { fadeInOut } from '@common/ui-sales-components';
import { BuildConfigService, LoaderService } from '@common/util-foundation';
import { BuildConfig } from '@common/util-models';
import {
  NavigationLink,
  NavigationLinks,
} from '@domgen/dgx-fe-business-models';
import { OffersRenewalsCommonSelectorsFacade } from '@oren/data-access-offer-renewal';
import { Observable, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ao-oren-app',
  templateUrl: './ao-oren-app.component.html',
  styleUrls: ['./ao-oren-app.component.scss'],
  animations: [fadeInOut],
})
export class AoOrenAppComponent implements OnInit, OnDestroy {
  title = 'ao-oren';
  config: BuildConfig = this.buildConfigService.config;
  headerNavigationLinks: NavigationLinks = this.config.header;
  loading$!: Observable<boolean>;

  public progressSteps$:
    | Observable<{ label: string; routes: string[] }[]>
    | undefined;
  public isInsurance$: Observable<boolean> | undefined;
  public currentRoute = '';
  public routeCheckoutOrderConfirmation = '/checkout/order-confirmation';
  private unSubscribe$: Subject<void> = new Subject<void>();

  footerProtectLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'protect'
  );
  footerUsefulLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'useful'
  );
  footerLegalLinks: NavigationLink[] = this.config.footer.links.filter(
    ({ section }) => section === 'legal'
  );

  constructor(
    private readonly buildConfigService: BuildConfigService,
    private readonly router: Router,
    private readonly loaderService: LoaderService,
    private readonly offersRenewalLandingPageFacade: OffersRenewalsCommonSelectorsFacade
  ) {
    this.router.events
      .pipe(
        takeUntil(this.unSubscribe$),
        filter((e): e is NavigationEnd => e instanceof NavigationEnd)
      )
      .subscribe((e: NavigationEnd) => {
        if (e.url) {
          this.currentRoute = e.url;
        }
      });
    this.progressSteps$ =
      this.offersRenewalLandingPageFacade.offerRenewalHasMultiplePaymentOptions$.pipe(
        distinctUntilChanged(),
        map((hasMultiplePaymentOptions) =>
          this.getSteps(hasMultiplePaymentOptions)
        )
      );
    this.isInsurance$ =
      this.offersRenewalLandingPageFacade.offerRenewalIsInsurance$;
  }

  ngOnInit(): void {
    this.loading$ = this.loaderService.loading$;
  }

  ngOnDestroy(): void {
    this.unSubscribe$.next();
    this.unSubscribe$.complete();
  }

  private getSteps(
    hasMultiplePaymentMethod: boolean
  ): { label: string; routes: string[] }[] {
    const yourDetails = {
      label: 'Your details',
      routes: ['/checkout/personal-details'],
    };
    const paymentMethod = {
      label: 'Payment method',
      routes: ['/checkout/select-payment'],
    };
    const paymentDetails = {
      label: 'Payment details',
      routes: [
        '/checkout/direct-debit-payment',
        '/checkout/card-payment',
        '/checkout/credit-card-payment',
        '/checkout/logged-in',
      ],
    };
    const confirmation = {
      label: 'Confirmation',
      routes: ['/checkout/order-confirmation'],
    };
    return [
      yourDetails,
      ...(hasMultiplePaymentMethod ? [paymentMethod] : []),
      paymentDetails,
      confirmation,
    ];
  }
}

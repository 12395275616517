import { Injectable } from '@angular/core';
import {
  DirectDebit,
  PaymentType,
  StopAutoRenewalCode,
} from '@common/util-models';
import { Store } from '@ngrx/store';
import {
  CheckoutPersonalDetailsFormValue,
  CheckoutSelectPaymentFormValue,
} from '@oren/util-foundation';
import { Observable } from 'rxjs';
import {
  checkoutDirectDebitFormSubmit,
  checkoutPersonalDetailsFormSubmit,
  checkoutSelectPaymentFormSubmit,
  checkoutSelectPaymentOption,
  restart,
  saveAutoRenewalPreference,
} from './checkout.actions';
import {
  getCheckoutPersonalDetailsFormValue,
  getEmailAddress,
} from './checkout.selectors';

@Injectable()
export class CheckoutFacade {
  checkoutPersonalDetailsFormValue$: Observable<CheckoutPersonalDetailsFormValue> =
    this.store.select(getCheckoutPersonalDetailsFormValue);

  emailAddress$: Observable<string | undefined> =
    this.store.select(getEmailAddress);

  constructor(private store: Store) {}

  checkoutPersonalDetailsFormSubmit(
    formValue: CheckoutPersonalDetailsFormValue
  ): void {
    this.store.dispatch(checkoutPersonalDetailsFormSubmit({ formValue }));
  }

  checkoutSelectPaymentFormSubmit(
    formValue: CheckoutSelectPaymentFormValue
  ): void {
    this.store.dispatch(checkoutSelectPaymentFormSubmit({ formValue }));
  }

  checkoutDirectDebitFormSubmit(formValue: DirectDebit): void {
    this.store.dispatch(checkoutDirectDebitFormSubmit({ formValue }));
  }

  selectPaymentOption(paymentType: PaymentType) {
    this.store.dispatch(checkoutSelectPaymentOption({ paymentType }));
  }

  restart(): void {
    this.store.dispatch(restart());
  }

  saveAutoRenewalPreference(isAutoRenewal: boolean) {
    const autoRenewalPreference = isAutoRenewal
      ? undefined
      : StopAutoRenewalCode.EndOfCurrentRenewal;
    this.store.dispatch(saveAutoRenewalPreference({ autoRenewalPreference }));
  }
}

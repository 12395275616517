import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
} from '@common/feature-core';
import { CommonUiSalesComponentsModule } from '@common/ui-sales-components';
import { CONFIG_ADAPTER, ENVIRONMENT } from '@common/util-foundation';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import { SkyErrorComponent } from '@domgen/dgx-ui-whitelabel-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OrenDataAccessCheckoutModule } from '@oren/data-access-checkout';
import { OrenDataAccessOfferRenewalModule } from '@oren/data-access-offer-renewal';
import { OrenUiComponentsModule } from '@oren/ui-components';
import { environment } from '@oren/util-environment';
import { QuotePageUrlConfigAdapter } from '@oren/util-foundation';
import { OrenAppComponent } from './components/oren-app/oren-app.component';
import { OfferRenewalMultiplePaymentOptionsGuard } from './guards/offer-renewal-multiple-payment-options/offer-renewal-multiple-payment-options.guard';
import { OfferRenewalGuard } from './guards/offer-renewal/offer-renewal.guard';
import { META_REDUCERS } from './meta-reducers/meta-reducers';
import { OrenFeatureShellRoutingModule } from './oren-feature-shell-router.module';
import { OfferQueryParamsResolver } from './resolvers/offer-query-params/offer-query-params.resolver';
import { OfferRenewalQueryParamsResolver } from './resolvers/offer-renewal-query-params/offer-renewal-query-params.resolver';
import { RenewalQueryParamsResolver } from './resolvers/renewal-query-params/renewal-query-params.resolver';

@NgModule({
  imports: [
    CommonModule,
    SharedUiComponentsCoreModule,
    CommonUiSalesComponentsModule,
    OrenFeatureShellRoutingModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    OrenDataAccessCheckoutModule,
    OrenDataAccessOfferRenewalModule,
    EffectsModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
    OrenUiComponentsModule,
  ],
  declarations: [OrenAppComponent, SkyErrorComponent],
  exports: [OrenAppComponent],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    OfferRenewalGuard,
    OfferRenewalMultiplePaymentOptionsGuard,
    OfferRenewalQueryParamsResolver,
    OfferQueryParamsResolver,
    RenewalQueryParamsResolver,
    {
      provide: CONFIG_ADAPTER,
      useClass: QuotePageUrlConfigAdapter,
      multi: true,
    },
  ],
})
export class OrenFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<OrenFeatureShellModule> {
    return {
      ngModule: OrenFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}

import { BuildConfig } from '@common/util-models';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

const headerLinks: BuildConfig['header'] = {
  home: {
    href: 'https://www.ao-care.com',
    label: '',
  },
  products: {
    label: 'Get protected',
    href: 'https://www.sales.ao-care.com/products',
  },
  repair: {
    label: 'Book a repair',
    href: 'https://www.ao-care.com/RepairClaimPageView?catalogId=17301&langId=44&storeId=14151',
  },
  faq: {
    label: 'Help and advice',
    href: 'https://www.ao-care.com/content/ao-care-help-advice/ao-faqs',
  },
  profile: {
    href: '/#',
    label: 'My account',
    icon: 'my-account',
    isHidden: true,
  },
  basket: {
    href: '/#',
    icon: 'basket',
    label: 'Basket',
    isHidden: true,
  },
  poweredBy: {
    href: '',
    label: '',
    isHidden: false,
  },
};
const privacyLink = {
  label: 'Privacy and Cookies policy',
  href: 'https://www.ao-care.com/privacy-cookies-policy',
  newWindow: true,
};
const footerLinks: NavigationLink[] = [
  {
    section: 'protect',
    label: 'Household appliances',
    href: 'https://www.ao-care.com/products-5/appliances-household',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Help and advice',
    href: 'https://www.ao-care.com/content/ao-care-help-advice/ao-faqs',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Contact us',
    href: 'https://www.ao-care.com/content/contact-us',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'ao.com',
    href: 'https://ao.com/',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Regulatory information',
    href: 'https://www.ao-care.com/company-information-legal',
    newWindow: true,
  },
  {
    section: 'useful',
    label: 'Modern Slavery Statement',
    href: 'https://www.ao-care.com/modern-slavery',
    newWindow: true,
  },

  {
    section: 'legal',
    label: 'Website Terms and Conditions',
    href: 'https://www.ao-care.com/web-terms-conditions',
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Privacy and Cookies Policy',
    href: 'https://www.ao-care.com/privacy-cookies-policy',
    newWindow: true,
  },
  {
    section: 'legal',
    label: 'Accessibility Policy',
    href: 'https://www.ao-care.com/accessibility',
    newWindow: true,
  },
];
export const DEFAULT_ENVIRONMENT = {
  header: headerLinks,
  footer: {
    links: footerLinks,
  },
  links: {
    privacy: privacyLink,
  },
  referrers: {
    jl: {
      redirect: 'https://www.myprotectplus.com',
    },
    dg: {
      redirect: 'https://www.domesticandgeneral.com',
    },
    ao: {
      redirect: 'https://www.ao-care.com',
    },
  },
  myAccountLink: 'https://www.myaccount.ao-care.com/my-account',
  dd_guarantee: 'https://www.ao-care.com/direct-debit-guarantee',
  wlClient: 'AOCARE',
  catalogueStore: 'AOCARE',
  cookieProSrc: 'https://cookie-cdn.cookiepro.com/scripttemplates/otSDKStub.js',
  cookieProDomainScript: 'bbf3b265-ca81-4054-929d-d396ddccdd63-test',
  action: 'QandB',
  offerPage: '/offer',
  renewalPage: '/renewal',
  quotePage: '/quote',
  checkoutLandingPage: '/checkout',
  checkoutOrderConfirmationPage: '/checkout/order-confirmation',
  applianceDetailsPage: '/products',
  checkoutPersonalDetailsPage: '/checkout/personal-details',
  checkoutDirectDebitPage: '/checkout/direct-debit-payment',
  checkoutCardPaymentPage: '/checkout/card-payment',
  checkoutLoggedInUserPage: '/checkout/logged-in',
  selectPaymentPage: '/checkout/select-payment',
  forgotPasswordPage: '/forgot-password',
  cms_config: {
    pagesRoot: '/dgx-oren',
    insideCmsEditor: false,
    logCmsRequests: false,
    useCachedCmsOutput: false,
    useFallbackCmsContent: false,
    cachedRouteBase: 'assets/cached-cms/pages',
    cachedContentAppBase: 'assets/cached-cms/content-apps/',
  },
  trustPilot: {
    businessUnitId: '4cb3113600006400050dfb02',
    templates: [
      {
        id: '5419b6ffb0d04a076446a9af',
        name: 'micro',
      },
      {
        id: '54ad5defc6454f065c28af8b',
        name: 'slider',
      },
    ],
  },
  cardPayment: {
    description: 'Sales',
    transactionSource: 'W',
    transactionType: 'W',
  },
  retryBackOffConfig: {
    maxRetries: 1,
    initialInterval: 3000,
    maxInterval: 3000,
  },
  postcodeServiceKey: 'ZG99-WD62-FP46-DC55',
  postcodeServiceFind:
    'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws',
  postcodeServiceRetrieve:
    'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws',
  worldpay: {
    customisation: {
      fonts: {
        family: ['Museo Sans', 'Source Sans Pro', 'Arial'],
      },
      color: '#414141',
      buttons: {
        backgroundColor: '#038ad3',
        color: '#fff',
        border: {
          style: 'solid',
          color: '#038ad3',
          width: '5px',
          radius: '2px',
        },
        hover: {
          backgroundColor: '#0071bb',
          border: {
            color: '#0071bb',
          },
        },
      },
      inputs: {
        backgroundColor: '#ffffff',
        border: {
          style: 'solid',
          width: '1px',
          radius: '2px',
          color: '#757575',
        },
        placeholder: {
          color: '#001',
        },
        validation: {
          ok: {
            color: '#228415',
          },
          error: {
            color: '#af2c2b',
          },
        },
      },
      page: {
        backgroundColor: '#fff',
        color: '#333',
        border: {
          style: 'solid',
          color: '#ddd',
          width: '1px',
          radius: '2px',
        },
      },
    },
  },
  error404: {
    contactUs: 'https://www.ao-care.com/content/contact-us',
    home: 'offer',
  },
  appRoot: '/',
};

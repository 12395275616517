import { ActionReducer } from '@ngrx/store';
import { QUOTES_FEATURE_KEY } from '@common/data-access-quotes';
import { LocalStorageConfig, localStorageSync } from 'ngrx-store-localstorage';
import { OFFER_RENEWAL_FEATURE_KEY } from '@oren/data-access-offer-renewal';
import { OREN_CHECKOUT_FEATURE_KEY } from '@oren/data-access-checkout';
import { USERPROFILE_FEATURE_KEY } from '@common/data-access-user-profile';
import { PERSONAL_DETAILS_FEATURE_KEY } from '@common/data-access-personal-details';
import { SELECTPAYMENT_FEATURE_KEY } from '@common/data-access-select-payment';
import { CARD_PAYMENT_FEATURE_KEY } from '@common/data-access-card-payment';
import { CHECKOUT_FEATURE_KEY } from '@common/data-access-checkout';

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [
      QUOTES_FEATURE_KEY,
      OFFER_RENEWAL_FEATURE_KEY,
      OREN_CHECKOUT_FEATURE_KEY,
      CHECKOUT_FEATURE_KEY,
      QUOTES_FEATURE_KEY,
      USERPROFILE_FEATURE_KEY,
      PERSONAL_DETAILS_FEATURE_KEY,
      SELECTPAYMENT_FEATURE_KEY,
      CARD_PAYMENT_FEATURE_KEY,
    ],
    storage: sessionStorage,
    rehydrate: true,
  } as LocalStorageConfig)(reducer);
}

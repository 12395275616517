import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OfferRenewalErrorService } from './services/error/error.service';
import { CommonUtilFoundationModule } from '@common/util-foundation';

@NgModule({
  imports: [CommonModule, CommonUtilFoundationModule],
  providers: [OfferRenewalErrorService],
})
export class OrenUtilFoundationModule {}

import { Action, createReducer, on } from '@ngrx/store';

import { OfferRenewalFormSubmit, QuoteType } from '@common/util-models';
import {
  getEmailOfferQuote,
  getEmailPlanRenewalQuote,
  getOfferQuote,
  getPlanRenewalQuote,
  getRedirectOfferQuote,
  getRedirectPlanRenewalQuote,
} from './offer-renewal.actions';

export const OFFER_RENEWAL_FEATURE_KEY = 'offerRenewal';

export interface OfferRenewalState {
  formData: OfferRenewalFormSubmit | null;
  quoteType: QuoteType | null;
}

export interface OfferRenewalPartialState {
  readonly [OFFER_RENEWAL_FEATURE_KEY]: OfferRenewalState;
}
export const initialState: OfferRenewalState = {
  formData: null,
  quoteType: null,
};

const offerRenewalReducer = createReducer(
  initialState,

  on(
    getOfferQuote,
    getPlanRenewalQuote,
    getRedirectOfferQuote,
    getRedirectPlanRenewalQuote,
    getEmailOfferQuote,
    getEmailPlanRenewalQuote,
    (state, { formData }) => ({
      ...state,
      formData,
    })
  ),
  on(getOfferQuote, (state) => ({
    ...state,
    quoteType: 'print-offer',
  })),
  on(getPlanRenewalQuote, (state) => ({
    ...state,
    quoteType: 'print-renewal',
  })),
  on(getEmailOfferQuote, (state) => ({
    ...state,
    quoteType: 'email-offer',
  })),
  on(getEmailPlanRenewalQuote, (state) => ({
    ...state,
    quoteType: 'email-renewal',
  })),
  on(getRedirectOfferQuote, (state, { referrer }) => ({
    ...state,
    quoteType: `${referrer}-offer` as QuoteType,
  })),
  on(getRedirectPlanRenewalQuote, (state, { referrer }) => ({
    ...state,
    quoteType: `${referrer}-renewal` as QuoteType,
  }))
);

export function reducer(state: OfferRenewalState | undefined, action: Action) {
  return offerRenewalReducer(state, action);
}

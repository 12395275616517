import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { environment } from '@ao-oren/util-environment';
import {
  AppProvidersConfig,
  AppProvidersConfigToken,
  CommonFeatureCoreModule,
} from '@common/feature-core';
import { CommonUiSalesComponentsModule } from '@common/ui-sales-components';
import { ENVIRONMENT } from '@common/util-foundation';
import { SharedUiComponentsCoreModule } from '@domgen/dgx-fe-components-core';
import {
  HeaderModule,
  SharedAoComponentsModule,
} from '@domgen/dgx-ui-whitelabel-components';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { OrenDataAccessCheckoutModule } from '@oren/data-access-checkout';
import { OrenDataAccessOfferRenewalModule } from '@oren/data-access-offer-renewal';
import {
  OfferQueryParamsResolver,
  OfferRenewalGuard,
  OfferRenewalMultiplePaymentOptionsGuard,
  OfferRenewalQueryParamsResolver,
  RenewalQueryParamsResolver,
} from '@oren/feature-shell';
import { OrenUiComponentsModule } from '@oren/ui-components';
import { AoOrenFeatureShellRoutingModule } from './ao-oren-feature-shell-router.module';
import { AoOrenAppComponent } from './components/ao-oren-app/ao-oren-app.component';
import { ErrorComponent } from './components/error/error.component';
import { META_REDUCERS } from './meta-reducers/meta-reducers';

@NgModule({
  imports: [
    CommonModule,
    HeaderModule,
    SharedAoComponentsModule,
    SharedUiComponentsCoreModule,
    CommonUiSalesComponentsModule,
    AoOrenFeatureShellRoutingModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production
          ? [...META_REDUCERS]
          : [...META_REDUCERS],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    OrenDataAccessCheckoutModule,
    OrenDataAccessOfferRenewalModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonFeatureCoreModule,
    EffectsModule.forRoot(),
    OrenUiComponentsModule,
  ],
  declarations: [AoOrenAppComponent, ErrorComponent],
  exports: [AoOrenAppComponent, ErrorComponent],
  providers: [
    {
      provide: ENVIRONMENT,
      useValue: environment,
    },
    OfferRenewalGuard,
    OfferRenewalMultiplePaymentOptionsGuard,
    OfferRenewalQueryParamsResolver,
    OfferQueryParamsResolver,
    RenewalQueryParamsResolver,
  ],
})
export class AoOrenFeatureShellModule {
  static forRoot(
    navigationConfig: AppProvidersConfig
  ): ModuleWithProviders<AoOrenFeatureShellModule> {
    return {
      ngModule: AoOrenFeatureShellModule,
      providers: [
        { provide: AppProvidersConfigToken, useValue: navigationConfig },
      ],
    };
  }
}

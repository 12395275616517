import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { EffectsModule } from '@ngrx/effects';
import { OfferRenewalEffects } from './+state/offer-renewal.effects';
import { OffersRenewalsCommonSelectorsFacade } from './facades/offers-renewals-common-selectors.facade';
import { OfferRenewalApiService } from './services/offer-renewal-api.service';
import { OrenUtilFoundationModule } from '@oren/util-foundation';
import { OfferRenewalLandingPageFacade } from './facades/offer-renewal-landing-page.facade';
import { StoreModule } from '@ngrx/store';
import * as fromOfferRenewal from './+state/offer-renewal.reducer';
import { CommonDataAccessQuotesModule } from '@common/data-access-quotes';
import { CommonDataAccessPersonalDetailsModule } from '@common/data-access-personal-details';
import { CommonDataAccessSelectPaymentModule } from '@common/data-access-select-payment';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    OrenUtilFoundationModule,
    CommonDataAccessQuotesModule,
    CommonDataAccessPersonalDetailsModule,
    CommonDataAccessSelectPaymentModule,
    EffectsModule.forFeature([OfferRenewalEffects]),
    StoreModule.forFeature(
      fromOfferRenewal.OFFER_RENEWAL_FEATURE_KEY,
      fromOfferRenewal.reducer
    ),
  ],
  providers: [
    OfferRenewalApiService,
    OfferRenewalLandingPageFacade,
    OffersRenewalsCommonSelectorsFacade,
  ],
})
export class OrenDataAccessOfferRenewalModule {}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CardPaymentSetupResolver } from '@common/data-access-card-payment';
import { SelectedPaymentTypeGuard } from '@common/data-access-select-payment';
import { Error404Component } from '@common/ui-whitelabel-components';
import { CmsPage, OFFER, PaymentType, RENEWAL } from '@common/util-models';
import { SkyErrorComponent } from '@domgen/dgx-ui-whitelabel-components';
import { OfferRenewalMultiplePaymentOptionsGuard } from './guards/offer-renewal-multiple-payment-options/offer-renewal-multiple-payment-options.guard';
import { OfferRenewalGuard } from './guards/offer-renewal/offer-renewal.guard';
import { OfferQueryParamsResolver } from './resolvers/offer-query-params/offer-query-params.resolver';
import { OfferRenewalQueryParamsResolver } from './resolvers/offer-renewal-query-params/offer-renewal-query-params.resolver';
import { RenewalQueryParamsResolver } from './resolvers/renewal-query-params/renewal-query-params.resolver';

const routes: Routes = [
  //todo: what should the default page actually be?
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/offer',
  },
  {
    path: 'error',
    data: { title: 'Error' },
    component: SkyErrorComponent,
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('@oren/feature-landing').then((m) => m.OrenFeatureLandingModule),
    resolve: {
      quoteParamsParseResponse: OfferRenewalQueryParamsResolver,
    },
  },
  {
    path: 'offer',
    loadChildren: () =>
      import('@oren/feature-landing').then((m) => m.OrenFeatureLandingModule),
    data: {
      cmsDataPage: CmsPage.OrenLandingOffer,
      referenceType: OFFER,
    },
    resolve: {
      quoteParamsParseResponse: OfferQueryParamsResolver,
    },
  },
  {
    path: 'renewal',
    loadChildren: () =>
      import('@oren/feature-landing').then((m) => m.OrenFeatureLandingModule),
    data: {
      cmsDataPage: CmsPage.OrenLandingRenewal,
      referenceType: RENEWAL,
    },
    resolve: {
      quoteParamsParseResponse: RenewalQueryParamsResolver,
    },
  },
  {
    path: 'quote',
    loadChildren: () =>
      import('@oren/feature-quote').then((m) => m.FeatureQuoteModule),
    canActivate: [OfferRenewalGuard],
    data: {
      redirectTo: '/',
    },
  },
  {
    path: 'checkout',
    children: [
      {
        path: 'personal-details',
        loadChildren: () =>
          import('@oren/feature-checkout-personal-details').then(
            (m) => m.OrenFeatureCheckoutPersonalDetailsModule
          ),
      },
      {
        path: 'select-payment',
        loadChildren: () =>
          import('@oren/feature-checkout-select-payment').then(
            (m) => m.OrenFeatureCheckoutSelectPaymentModule
          ),
        canActivate: [OfferRenewalMultiplePaymentOptionsGuard],
      },
      {
        path: 'direct-debit-payment',
        loadChildren: () =>
          import('@oren/feature-checkout-direct-debit').then(
            (m) => m.OrenFeatureCheckoutDirectDebitModule
          ),
        canActivate: [SelectedPaymentTypeGuard],
        data: {
          paymentType: PaymentType.DirectDebit,
        },
      },
      {
        path: 'card-payment',
        loadChildren: () =>
          import('@oren/feature-checkout-card-payment').then(
            (m) => m.OrenFeatureCheckoutCardPaymentModule
          ),
        canActivate: [SelectedPaymentTypeGuard],
        data: {
          paymentType: PaymentType.Card,
        },
        resolve: {
          setupCardPayment: CardPaymentSetupResolver,
        },
      },
      {
        path: 'order-confirmation',
        loadChildren: () =>
          import('@oren/feature-order-confirmation').then(
            (m) => m.OrenFeatureOrderConfirmationModule
          ),
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'personal-details',
      },
    ],
    canActivate: [OfferRenewalGuard],
    data: {
      redirectTo: '/',
    },
  },
  {
    path: '**',
    component: Error404Component,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      paramsInheritanceStrategy: 'always',
      relativeLinkResolution: 'corrected',
    }),
  ],
  exports: [RouterModule],
})
export class OrenFeatureShellRoutingModule {} // Main routes for application

<div class="quote-summary-total">
  <!-- Discount -->
  <div *ngIf="hasDiscount; else fullPayment">
    <p
      class="quote-summary-total__breakdown quote-summary-total__breakdown--bold"
    >
      <span class="quote-summary-total__breakdown-title">
        First {{ discountPeriod }} months
      </span>

      £{{ firstDiscountPayment || 0 | number: '1.2-2' }} a month
    </p>
    <p class="quote-summary-total__breakdown">
      <span class="quote-summary-total__breakdown-title">
        Following months ({{
          discountPeriod ? discountPeriod + 1 : defaultDiscountPeriod
        }}
        to {{ numberOfMonthlyPayments }})
      </span>

      £{{ subsequentPayment || 0 | number: '1.2-2' }} a month
    </p>
    <p class="quote-summary-total__breakdown py-6">
      <span class="quote-summary-total__breakdown-title">
        Total cost over {{ numberOfMonthlyPayments }} months
      </span>

      £{{ totalWithDiscount | number: '1.2-2' }}
    </p>
    <ng-container
      *ngIf="isExpanded"
      [ngTemplateOutlet]="quoteSummaryTotalFooter"
    ></ng-container>
  </div>

  <ng-template #fullPayment>
    <!-- Card -->
    <div *ngIf="selectedPaymentTypeIsCard" data-test-hook="card-total">
      <p
        class="quote-summary-total__breakdown quote-summary-total__breakdown--bold"
      >
        <span class="quote-summary-total__breakdown-title">
          Your quote total
        </span>

        £{{ cardTotal || 0 | number: '1.2-2' }}
      </p>
      <ng-container
        *ngIf="cardTotal && isExpanded"
        [ngTemplateOutlet]="quoteSummaryTotalFooter"
      ></ng-container>
    </div>

    <!-- Direct Debit / Fixed Term plan -->
    <div
      *ngIf="!selectedPaymentTypeIsCard && isFixedTerm"
      data-test-hook="direct-debit-fixed-term-total"
    >
      <p
        class="quote-summary-total__breakdown quote-summary-total__breakdown--bold"
      >
        <span class="quote-summary-total__breakdown-title">
          Your quote total
        </span>

        £{{ subsequentMonthlyPaymentTotal || 0 | number: '1.2-2' }}
        a month
      </p>

      <p
        *ngIf="directDebitTotal"
        class="quote-summary-total__breakdown"
        data-test-hook="direct-debit-fixed-term-total-cost"
      >
        <span class="quote-summary-total__breakdown-title">
          Total cost over {{ numberOfMonthlyPayments }} months
        </span>

        £{{ directDebitTotal || 0 | number: '1.2-2' }}
      </p>
      <ng-container
        *ngIf="directDebitTotal && isExpanded"
        [ngTemplateOutlet]="quoteSummaryTotalFooter"
      ></ng-container>
    </div>

    <!-- Direct Debit / Rolling term plan-->
    <div
      *ngIf="!selectedPaymentTypeIsCard && !isFixedTerm"
      data-test-hook="direct-debit-rolling-term-total"
    >
      <p
        class="quote-summary-total__breakdown quote-summary-total__breakdown--bold"
      >
        <span class="quote-summary-total__breakdown-title">
          Your quote total
        </span>

        £{{ subsequentMonthlyPaymentTotal || 0 | number: '1.2-2' }}
        a month
      </p>

      <ng-container
        *ngIf="subsequentMonthlyPaymentTotal && isExpanded"
        [ngTemplateOutlet]="quoteSummaryTotalFooter"
      ></ng-container>
    </div>
  </ng-template>

  <ng-template #quoteSummaryTotalFooter>
    <div
      class="quote-summary-total__footer"
      data-test-hook="quote-summary-total-footer"
    >
      <p
        *ngIf="
          !selectedPaymentTypeIsCard &&
          directDebitDiscount &&
          directDebitDiscount > 0 &&
          !storeDiscountPercentage
        "
        class="quote-summary-total__note quote-summary-total__note--bold"
      >
        Includes £{{ directDebitDiscount | number: '1.2-2' }} Direct Debit
        discount
      </p>
      <p
        *ngIf="!selectedPaymentTypeIsCard && storeDiscountPercentage"
        class="quote-summary-total__note quote-summary-total__note--bold"
      >
        Includes {{ storeDiscountPercentage }}% discount
      </p>
      <p
        *ngIf="!selectedPaymentTypeIsCard && storeDiscountPercentage"
        class="quote-summary-total__note"
      >
        Discount only applies to items older than 3 months, and less than 8
        years old.
      </p>
      <p class="quote-summary-total__note">
        All prices include all applicable taxes (including
        {{ applianceIsWhiteGoods ? 'IPT' : 'VAT' }}).
      </p>
    </div>
  </ng-template>
</div>

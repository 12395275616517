import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'oren-need-to-know',
  templateUrl: './need-to-know.component.html',
  styleUrls: ['./need-to-know.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NeedToKnowComponent {
  @Input() content: string | undefined;
}

<ui-panel>
  <ui-panel-header>
    <ui-panel-title
      >Your {{ isInsurance ? 'policy' : 'plan' }} duration
    </ui-panel-title>
  </ui-panel-header>
  <ui-panel-body>
    <p class="pt-4">
      Your {{ isInsurance ? 'policy' : 'plan' }} will start 30 days after your
      purchase, and will be valid for one year. Your protection is provided by
      {{
        isInsurance
          ? 'Domestic & General Insurance PLC'
          : 'Domestic & General Services Limited'
      }}.
    </p>
    <p class="py-6" *ngIf="isDirectDebit">
      Paying by Direct Debit ensures automatic renewal each year<span
        *ngIf="isInsurance"
        >, unless you tell us you don't want it to (see above) </span
      >. We will write to you before your renewal date.<span
        *ngIf="isInsurance"
      >
        If you have opted out of automatic renewal, your notice will include
        instructions on how to pay.
      </span>
    </p>
  </ui-panel-body>
</ui-panel>

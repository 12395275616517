import { FeatureConfigSelectors } from '@common/data-access-feature-config';
import { PersonalDetailsSelectors } from '@common/data-access-personal-details';
import {
  QuotesSelectors,
  QuotesState,
  QuotesUtils,
} from '@common/data-access-quotes';
import { applianceIsHeating } from '@common/util-foundation';
import {
  BasketItem,
  BasketItemApplianceDetails,
  ContractType,
  Cover,
  CoverPaymentOptions,
  CoverType,
  CustomerDetails,
  ItemType,
  PersonalDetails,
  Quote,
  YourDetails,
} from '@common/util-models';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  OfferRenewalPartialState,
  OfferRenewalState,
  OFFER_RENEWAL_FEATURE_KEY,
} from './offer-renewal.reducer';

export const getOfferRenewalState = createFeatureSelector<OfferRenewalState>(
  OFFER_RENEWAL_FEATURE_KEY
);

export const getOfferRenewalBasketItem = createSelector(
  QuotesSelectors.getAllItems,
  (basketItems: BasketItem[]): BasketItem | undefined =>
    basketItems.find(
      (basketItem: BasketItem) =>
        basketItem.itemType === ItemType.Offer ||
        basketItem.itemType === ItemType.Renewal
    )
);

export const getOfferRenewalApplianceDetails = createSelector(
  getOfferRenewalBasketItem,
  (
    basketItem: BasketItem | undefined
  ): BasketItemApplianceDetails | undefined => {
    return basketItem?.data?.applianceDetails;
  }
);

export const getOfferRenewalType = createSelector(
  getOfferRenewalBasketItem,
  (quote: BasketItem | undefined) => quote?.itemType
);

export const getGaQuoteType = createSelector(
  getOfferRenewalState,
  (state: OfferRenewalState) => state.quoteType
);

export const getOfferRenewal = createSelector(
  QuotesSelectors.getSelectedCoverType,
  getOfferRenewalBasketItem,
  FeatureConfigSelectors.getQuotesConfig,
  (
    selectedCoverType: CoverType,
    quoteDetails: BasketItem | undefined,
    config
  ): Cover | undefined => {
    const quoteDetail = quoteDetails?.data?.quotes?.find(
      (quoteDetail) =>
        quoteDetail.coverType === selectedCoverType &&
        quoteDetail.excessAmount === 0
    );
    if (!quoteDetail) {
      return;
    }
    const name =
      config?.coverTypeNames && config?.coverTypeNames[selectedCoverType];

    return { name, ...QuotesUtils.transformQuoteDetailToCover(quoteDetail) };
  }
);

export const getOfferRenewalQuote = createSelector(
  getOfferRenewalBasketItem,
  getOfferRenewalApplianceDetails,
  getOfferRenewal,
  (
    offerRenewalBasketItem: BasketItem | undefined,
    applianceDetails: BasketItemApplianceDetails | undefined,
    offerRenewal: Cover | undefined
  ): Quote | undefined => {
    if (!applianceDetails || !offerRenewal || !offerRenewalBasketItem) {
      return undefined;
    }

    return {
      applianceCode: applianceDetails.applianceCode,
      applianceCategory: applianceDetails.applianceCategory,
      applianceName: applianceDetails.applianceName,
      brandCode: applianceDetails.brandCode,
      brandName: applianceDetails.brandName,
      purchaseDate: new Date(
        applianceDetails.purchaseYear,
        applianceDetails.purchaseMonth - 1
      ),
      applianceIcon: applianceDetails.applianceIcon,
      cover: offerRenewal,
      itemId: offerRenewalBasketItem.itemId,
      itemType: offerRenewalBasketItem.itemType,
    };
  }
);

export const getOfferRenewalReference = createSelector(
  getOfferRenewalBasketItem,
  (quote: BasketItem | undefined) => {
    return quote?.data?.offerDetails
      ? quote?.data?.offerDetails.offerReference
      : quote?.data?.renewDetails?.reference;
  }
);

export const getOfferRenewalPaymentOptions = createSelector(
  getOfferRenewal,
  (quote: Cover | undefined) => {
    const paymentOptions = quote?.paymentOptions || undefined;

    if (paymentOptions?.directDebit?.fee && paymentOptions?.card?.fee) {
      return {
        ...paymentOptions,
        directDebit: {
          ...paymentOptions.directDebit,
          directDebitDiscount:
            paymentOptions.card.fee - paymentOptions.directDebit.fee,
        },
      };
    }
    return paymentOptions;
  }
);

export const getOfferRenewalHasMultiplePaymentOptions = createSelector(
  getOfferRenewalPaymentOptions,
  (paymentOptions?: CoverPaymentOptions) =>
    !!paymentOptions?.card && !!paymentOptions?.directDebit
);

export const getOfferRenewalHasCardPaymentOption = createSelector(
  getOfferRenewalPaymentOptions,
  (paymentOptions?: CoverPaymentOptions) => !!paymentOptions?.card
);

export const getOfferRenewalHasDirectDebitPaymentOption = createSelector(
  getOfferRenewalPaymentOptions,
  (paymentOptions?: CoverPaymentOptions) => !!paymentOptions?.directDebit
);

export const getOfferRenewalCustomer = createSelector(
  QuotesSelectors.getQuotesState,
  (state: QuotesState) => {
    return state?.quoteApiResult?.customer;
  }
);

export const getOfferRenewalMergedCustomerDetails = createSelector(
  getOfferRenewalCustomer,
  PersonalDetailsSelectors.getPersonalDetails,
  (
    customerDetails?: CustomerDetails,
    personalDetails?: PersonalDetails
  ): YourDetails | undefined => {
    if (!customerDetails) {
      return;
    }
    const fullName: string = [
      personalDetails?.title ?? customerDetails.title,
      personalDetails?.firstName ??
        customerDetails.firstName ??
        customerDetails.initial,
      personalDetails?.lastName ?? customerDetails.surname,
    ]
      .filter((name?: string) => !!name)
      .join(' ');

    return {
      name: fullName,
      email: personalDetails?.email ?? customerDetails.email?.main,
      mobile:
        personalDetails?.mobileNumber ?? customerDetails.telephone?.mobile,
      landline: customerDetails.telephone?.home,
      address: customerDetails.address,
    };
  }
);

export const getOfferRenewalIsInsurance = createSelector(
  getOfferRenewal,
  (offerRenewal: Cover | undefined) =>
    offerRenewal?.contractTypeCode == ContractType.Insurance
);

export const getOfferRenewalCustomerFirstName = createSelector(
  getOfferRenewalCustomer,
  (customerDetails?: CustomerDetails): string | undefined => {
    return customerDetails ? customerDetails.firstName : undefined;
  }
);

export const offerRenewalIsEmailAvailable = createSelector(
  getOfferRenewalCustomer,
  (customerDetails?: CustomerDetails): boolean => {
    return !!customerDetails?.email;
  }
);

export const getOfferRenewalisHeatingAppliance = createSelector(
  getOfferRenewalApplianceDetails,
  (applianceDetails: BasketItemApplianceDetails | undefined) =>
    applianceIsHeating(applianceDetails)
);

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PaymentTerm } from '@common/util-models';
import { CoverDurationCmsData } from '@oren/util-foundation';
@Component({
  selector: 'oren-cover-duration-summary',
  templateUrl: './cover-duration-summary.component.html',
  styleUrls: ['./cover-duration-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CoverDurationSummaryComponent {
  @Input() cmsData: CoverDurationCmsData | undefined;
  @Input() coverPaymentTerm: string | undefined;
  @Input() coverDurationInMonths?: number;
  @Input() planOrPolicy: 'plan' | 'policy' = 'plan';

  get fixedTermCmsData() {
    return (
      (this.planOrPolicy === 'plan'
        ? this.cmsData?.fixedTermSummaryForPlan
        : this.cmsData?.fixedTermSummaryForPolicy) ?? ''
    );
  }

  public paymentTerm: typeof PaymentTerm = PaymentTerm;
}

import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { OfferRenewalFormSubmit } from '@common/util-models';
import { CmsFormField } from '@domgen/dgx-fe-business-models';
import {
  DynamicFormbuilderService,
  FieldDef,
} from '@domgen/dgx-fe-dynamic-form-builder';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { OfferRenewalFormConfigService } from './offer-renewal-form-config.service';

export interface OfferRenewalFormState {
  cmsFormData: CmsFormField[];
  form: UntypedFormGroup;
  formBuilderConfig: FieldDef[];
  prefilledForm?: OfferRenewalFormSubmit | null;
  referenceType?: string;
  submittedForm?: OfferRenewalFormSubmit;
  validate: boolean;
}

@Injectable()
export class OfferRenewalFormStateService extends ComponentStore<OfferRenewalFormState> {
  readonly formBuilderConfig$ = this.select(
    (state: OfferRenewalFormState) => state.formBuilderConfig
  );

  readonly formGroup$ = this.select(
    (state: OfferRenewalFormState) => state.form
  );

  readonly cmsFormData$ = this.select(
    (state: OfferRenewalFormState) => state.cmsFormData
  ).pipe(filter((cmsData) => !!cmsData.length));

  readonly referenceType$ = this.select(
    (state: OfferRenewalFormState) => state.referenceType
  ).pipe(filter((referenceType) => referenceType !== undefined));

  readonly validate$ = this.select(
    (state: OfferRenewalFormState) => state.validate
  );

  readonly submittedForm$ = this.select(
    (state: OfferRenewalFormState) => state.submittedForm
  ).pipe(filter((submittedForm) => !!submittedForm));

  readonly prefilledForm$ = this.select(
    (state: OfferRenewalFormState) => state.prefilledForm
  ).pipe(
    filter((formData) => formData !== undefined)
  ) as Observable<OfferRenewalFormSubmit | null>;

  validatedSubmit$ = this.select(
    this.submittedForm$,
    (submittedForm) =>
      ({
        ...submittedForm,
      } as OfferRenewalFormSubmit)
  );
  readonly setCmsFormData = this.updater(
    (state: OfferRenewalFormState, cmsFormData: CmsFormField[]) => ({
      ...state,
      cmsFormData,
    })
  );

  readonly updateSubmittedForm = this.updater(
    (state: OfferRenewalFormState, submittedForm: OfferRenewalFormSubmit) => ({
      ...state,
      submittedForm,
    })
  );

  readonly updateFormValue = this.updater(
    (state: OfferRenewalFormState, value: unknown) => ({
      ...state,
      formValue: value as OfferRenewalFormSubmit,
    })
  );

  readonly setReferenceType = this.updater(
    (state: OfferRenewalFormState, referenceType: string | undefined) => ({
      ...state,
      referenceType,
    })
  );

  readonly setPrefilledFormData = this.updater(
    (
      state: OfferRenewalFormState,
      prefilledForm: OfferRenewalFormSubmit | null
    ) => ({
      ...state,
      prefilledForm,
    })
  );

  readonly validate = this.updater(
    (state: OfferRenewalFormState, validate: boolean) => ({
      ...state,
      validate,
    })
  );

  readonly vm$ = this.select(
    this.formBuilderConfig$,
    this.formGroup$,
    this.validate$,
    (fieldDef, formGroup, validate) => ({
      fieldDef,
      formGroup,
      validate,
    })
  );

  // *** Updater Sources ****
  private fieldDefinitionUpdater$ = combineLatest([
    this.cmsFormData$,
    this.referenceType$,
    this.prefilledForm$,
  ]).pipe(
    map(([cmsData, referenceType, formData]) => {
      return this.offerRenewalFormConfig.getFormbuilderConfig(
        cmsData,
        referenceType,
        formData
      );
    })
  );

  private readonly cmsFieldDefinitionUpdater = this.updater(
    (state: OfferRenewalFormState, formBuilderConfig: FieldDef[]) => ({
      ...state,
      formBuilderConfig,
    })
  )(this.fieldDefinitionUpdater$);

  private formGroupUpdater$ = this.formBuilderConfig$.pipe(
    map((conf) => this.dynamicFormBuilder.generateFormGroup(conf))
  );

  private readonly formGroupUpdater = this.updater(
    (state: OfferRenewalFormState, value: UntypedFormGroup) => ({
      ...state,
      form: value,
    })
  )(this.formGroupUpdater$);

  constructor(
    private offerRenewalFormConfig: OfferRenewalFormConfigService,
    private dynamicFormBuilder: DynamicFormbuilderService
  ) {
    super({
      formBuilderConfig: [],
      cmsFormData: [],
      form: new UntypedFormGroup({}),
      validate: false,
    });
  }
}

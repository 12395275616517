import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCheckout from './+state/checkout.reducer';
import { CheckoutEffects } from './+state/checkout.effects';
import { CheckoutFacade } from './+state/checkout.facade';
import { CommonDataAccessQuotesModule } from '@common/data-access-quotes';
import { CommonDataAccessSelectPaymentModule } from '@common/data-access-select-payment';

@NgModule({
  imports: [
    CommonModule,
    CommonDataAccessQuotesModule,
    CommonDataAccessSelectPaymentModule,
    StoreModule.forFeature(
      fromCheckout.OREN_CHECKOUT_FEATURE_KEY,
      fromCheckout.reducer
    ),
    EffectsModule.forFeature([CheckoutEffects]),
  ],
  providers: [CheckoutFacade],
})
export class OrenDataAccessCheckoutModule {}

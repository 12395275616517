import { Injectable } from '@angular/core';
import { ItemType, OfferRenewalFormSubmit } from '@common/util-models';
import { OfferRenewalFormField } from '@domgen/dgx-fe-business-models';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { getOfferRenewalFormData } from '../+state/offer-renewal-landing-page.selectors';
import {
  getEmailOfferQuote,
  getEmailPlanRenewalQuote,
  getOfferQuote,
  getPlanRenewalQuote,
  getRedirectOfferQuote,
  getRedirectPlanRenewalQuote,
} from '../+state/offer-renewal.actions';
import { OfferRenewalPartialState } from '../+state/offer-renewal.reducer';

@Injectable()
export class OfferRenewalLandingPageFacade {
  formData$ = this.store.select(getOfferRenewalFormData).pipe(first());

  constructor(private store: Store<OfferRenewalPartialState>) {}

  getOffer(reference: string, postalCode: string) {
    this.store.dispatch(
      getOfferQuote({
        offerQuoteRequest: {
          itemType: ItemType.Offer,
          postalCode,
          reference,
        },
        formData: {
          [OfferRenewalFormField.PostCode]: postalCode,
          [OfferRenewalFormField.ReferenceNumber]: reference,
        } as OfferRenewalFormSubmit,
      })
    );
  }

  getEmailOffer(reference: string, postalCode: string) {
    this.store.dispatch(
      getEmailOfferQuote({
        offerQuoteRequest: {
          itemType: ItemType.Offer,
          postalCode,
          reference,
        },
        formData: {
          [OfferRenewalFormField.PostCode]: postalCode,
          [OfferRenewalFormField.ReferenceNumber]: reference,
        } as OfferRenewalFormSubmit,
      })
    );
  }

  getRedirectOffer(reference: string, postalCode: string, referrer: string) {
    this.store.dispatch(
      getRedirectOfferQuote({
        offerQuoteRequest: {
          itemType: ItemType.Offer,
          postalCode,
          reference,
        },
        formData: {
          [OfferRenewalFormField.PostCode]: postalCode,
          [OfferRenewalFormField.ReferenceNumber]: reference,
        } as OfferRenewalFormSubmit,
        referrer,
      })
    );
  }

  getRenewal(reference: string, postalCode: string) {
    this.store.dispatch(
      getPlanRenewalQuote({
        planRenewalQuoteRequest: {
          itemType: ItemType.Renewal,
          postalCode,
          reference,
        },
        formData: {
          [OfferRenewalFormField.PostCode]: postalCode,
          [OfferRenewalFormField.PlanNumber]: reference,
        } as OfferRenewalFormSubmit,
      })
    );
  }

  getEmailRenewal(reference: string, postalCode: string) {
    this.store.dispatch(
      getEmailPlanRenewalQuote({
        planRenewalQuoteRequest: {
          itemType: ItemType.Renewal,
          postalCode,
          reference,
        },
        formData: {
          [OfferRenewalFormField.PostCode]: postalCode,
          [OfferRenewalFormField.PlanNumber]: reference,
        } as OfferRenewalFormSubmit,
      })
    );
  }

  getRedirectRenewal(reference: string, postalCode: string, referrer: string) {
    this.store.dispatch(
      getRedirectPlanRenewalQuote({
        planRenewalQuoteRequest: {
          itemType: ItemType.Renewal,
          postalCode,
          reference,
        },
        formData: {
          [OfferRenewalFormField.PostCode]: postalCode,
          [OfferRenewalFormField.PlanNumber]: reference,
        } as OfferRenewalFormSubmit,
        referrer,
      })
    );
  }
}

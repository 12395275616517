import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutActions as CommonCheckoutActions } from '@common/data-access-checkout';
import * as fromPersonalDetailsActions from '@common/data-access-personal-details';
import { QuotesPartialState } from '@common/data-access-quotes';
import { SelectPaymentActions } from '@common/data-access-select-payment';
import {
  PersonalDetailsSharedActions,
  ResetStateActions,
} from '@common/data-access-shared';
import { BuildConfigService, ofTypeSequence } from '@common/util-foundation';
import { DirectDebit, ItemType, PaymentType } from '@common/util-models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import {
  getOfferRenewalCustomer,
  getOfferRenewalHasCardPaymentOption,
  getOfferRenewalHasDirectDebitPaymentOption,
  getOfferRenewalHasMultiplePaymentOptions,
  getOfferRenewalType,
  OfferRenewalPartialState,
} from '@oren/data-access-offer-renewal';
import { CheckoutSelectPaymentFormValue } from '@oren/util-foundation';
import { Observable } from 'rxjs';
import { filter, map, mapTo, tap, withLatestFrom } from 'rxjs/operators';
import * as CheckoutActions from './checkout.actions';
import { getEmailAddress } from './checkout.selectors';

@Injectable()
export class CheckoutEffects {
  routeToCheckoutPayment$: Observable<void> = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutActions.checkoutPersonalDetailsFormSubmit),
        withLatestFrom(
          this.store$.pipe(select(getOfferRenewalHasMultiplePaymentOptions)),
          this.store$.pipe(select(getOfferRenewalHasCardPaymentOption)),
          this.store$.pipe(select(getOfferRenewalHasDirectDebitPaymentOption))
        ),
        map(
          ([
            ,
            multiplePaymentOptions,
            cardPaymentOption,
            directDebitPaymentOption,
          ]) => {
            let route = '/';

            if (multiplePaymentOptions) {
              route = this.buildConfigService.config.selectPaymentPage;
            } else if (cardPaymentOption) {
              route = this.buildConfigService.config.checkoutCardPaymentPage;
            } else if (directDebitPaymentOption) {
              route = this.buildConfigService.config.checkoutDirectDebitPage;
            }

            this.navigateByUrl(route);
          }
        )
      ),
    { dispatch: false }
  );

  updatePersonalDetailsState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.checkoutPersonalDetailsFormSubmit),
      withLatestFrom(
        this.store$.pipe(select(getOfferRenewalCustomer)),
        this.store$.pipe(select(getEmailAddress))
      ),
      map(([, customer, email]) =>
        PersonalDetailsSharedActions.setPersonalDetails({
          payload: {
            address: {
              line1: customer?.address?.addressLine1 ?? '',
              line2: customer?.address?.addressLine2 ?? '',
              city: '',
              postcode: customer?.address?.postalCode ?? '',
              county: customer?.address?.addressLine4 ?? '',
            },
            email: email || customer?.email?.main,
            firstName: customer?.firstName,
            lastName: customer?.surname,
            mobileNumber: customer?.telephone?.mobile,
            title: customer?.title,
          },
        })
      )
    )
  );

  checkoutSelectPaymentFormSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.checkoutSelectPaymentFormSubmit),
      map((action: { formValue: CheckoutSelectPaymentFormValue }) =>
        SelectPaymentActions.selectPaymentOption({
          paymentType: action.formValue.paymentType,
        })
      )
    )
  );

  checkoutSelectPaymentOption$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.checkoutSelectPaymentOption),
      map((action) =>
        SelectPaymentActions.selectPaymentOption({
          paymentType: action.paymentType,
        })
      )
    )
  );

  routeToSelectedPaymentMethod$ = createEffect(
    () =>
      this.actions$.pipe(
        ofTypeSequence(
          CheckoutActions.checkoutSelectPaymentFormSubmit,
          SelectPaymentActions.selectPaymentOption
        ),
        tap((action: { type: string; paymentType: PaymentType }) => {
          action.paymentType === PaymentType.DirectDebit
            ? this.navigateByUrl(
                this.buildConfigService.config.checkoutDirectDebitPage
              )
            : this.navigateByUrl(
                this.buildConfigService.config.checkoutCardPaymentPage
              );
        })
      ),
    { dispatch: false }
  );

  checkoutDirectDebitFormSubmit$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.checkoutDirectDebitFormSubmit),
      map((action: { formValue: DirectDebit }) =>
        fromPersonalDetailsActions.updateDirectDebit({
          payload: action.formValue,
        })
      )
    )
  );

  checkout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromPersonalDetailsActions.updateDirectDebit),
      map(() => CommonCheckoutActions.checkoutGuest())
    )
  );

  redirectToOfferRenewal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.restart),
      withLatestFrom(this.store$.pipe(select(getOfferRenewalType))),
      filter(([, type]) => type !== undefined),
      tap(([, type]: [Action, ItemType | undefined]) => {
        const url = (
          type === ItemType.Offer
            ? this.buildConfigService.config.offerPage
            : this.buildConfigService.config.renewalPage
        ) as string;

        this.navigateByUrl(url);
      }),
      mapTo(ResetStateActions.resetState())
    )
  );

  resetAutoRenewal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutActions.checkoutPersonalDetailsFormSubmit),
      map(() =>
        CheckoutActions.saveAutoRenewalPreference({
          autoRenewalPreference: undefined,
        })
      )
    )
  );

  constructor(
    private actions$: Actions,
    private buildConfigService: BuildConfigService,
    private router: Router,
    private store$: Store<QuotesPartialState & OfferRenewalPartialState>
  ) {}

  private navigateByUrl(url: string) {
    this.router.navigateByUrl(url);
  }
}

import { QuoteCms } from '@common/util-models';

export interface CoverDurationCmsData {
  title: string;
  fixedTermSummaryForPlan: string;
  fixedTermSummaryForPolicy: string;
  summary: string;
}

export interface Insurance {
  title: string;
  included: string[];
}

export interface Maintenance {
  title: string;
  included: string[];
}

export interface OfferRenewalCmsData extends QuoteCms {
  coverDuration: CoverDurationCmsData;
  insurance: Insurance;
  maintenance: Maintenance;
}

<ui-panel>
  <ui-panel-header>
    <ui-panel-title>Your details</ui-panel-title>
  </ui-panel-header>
  <ui-panel-body [dgxQmSanitise]="'block'" *ngIf="detail" class="detail-panel">
    <ui-panel-display-info-row
      *ngFor="let row of detail"
      [data]="row"
    ></ui-panel-display-info-row>
  </ui-panel-body>
</ui-panel>

import { REFERENCE_NUMBER_REGEX } from '@common/util-foundation';

export function formatReferenceNumber(
  value: string | undefined
): string | undefined {
  if (!value) {
    return;
  }

  const threeDigitGroupRegexp = new RegExp(/([\s-]?[\d]{1}){3}/g);
  const threeDigitGroups: RegExpMatchArray | null = value.match(
    threeDigitGroupRegexp
  );

  if (!threeDigitGroups || !REFERENCE_NUMBER_REGEX.test(value)) {
    return undefined;
  }

  const formattedValue: string = threeDigitGroups
    .map((threeDigitGroup: string) => threeDigitGroup.replace(/[\s-/]*/g, ''))
    .join('-');

  return formattedValue;
}
